.landing-pg-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 275px);
}

.landing-pg-text-content{
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bulletpoint-container {
    margin-top: 50px;
    display: flex;
    max-width: 1500px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #ccc;
    background-color: #333;
}

.bulletpoint {
    width: 100%;
    text-align: left;
}

.landing-pg-logos {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.logos-top {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.logo-bottom {
    
}

.logo {
    margin: 20px 30px;
}

@media (max-width: 600px) {
    .bulletpoint-container {
        width: 90%;
        border: none;
        border-radius: 0;
    }
}