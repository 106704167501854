/* Modal container */
.modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #444;
    color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
}
.modal-content-header {
    text-align: center;
}

.modal-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
}

/* Blur the background */
.blur-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    backdrop-filter: blur(5px); /* Apply the blur effect */
    z-index: 999; /* Make sure the background appears behind the modal */
}