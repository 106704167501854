
  /* Styles for the search bar container */
  .search-container {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 400px;
    width: 400px;
    margin: 2rem 0;
  }
  
  /* Styles for the search input */
  .search-input {
    width: 100%;
    padding: 10px 15px;
    border: none;
    border-radius: 20px;
    background-color: #2b2b2b; /* Dark background color */
    color: #fff; /* Text color */
    font-size: 16px;
    outline: none;
  }
  
  /* Styles for the search button */
  .search-button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 8px 15px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif;
    background-color: #2b2b2b;
  }

  .search-button:hover {
    background-color: #555;
  }

  .text-optimize-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .optimized-text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border: 1px solid #59b384;
  }

  /* Styles for the text input container */
  .text-input-container {
    position: relative;
    max-width: 800px; /* Increased max-width for better readability */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* Styles for the text input */
  .text-input {
    width: 100%;
    height: 600px; /* Adjust height for multiple paragraphs */
    padding: 10px 15px;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 10px; /* Reduced border-radius for a cleaner look */
    background-color: white; /* Light background color */
    color: #222; /* Text color */
    font-size: 16px;
    outline: none;
    resize: vertical; /* Allow vertical resizing */
  }

  /* Styles for disabled textarea */
  .text-input:disabled {
    background-color: #f2f2f2; /* Light gray background when disabled */
    color: #999; /* Light gray text color when disabled */
    cursor: text; /* Show disabled cursor */
    border-color: #ccc; /* Light gray border color when disabled */
  }

  .optimize-action-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  /* Styles for the keyword container */
  .keyword-container {
    width: 100%;
    margin: 2rem auto;
    max-width: 600px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* Styles for the keyword input */
  #keyword-input {
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
  }

  #keyword-input:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  #keyword-input:disabled::placeholder {
    opacity: 0;
  }

  #keyword-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  /* Styles for the keyword boxes */
  .keyword-box {
    display: inline-block;
    padding: 5px 10px;
    margin: 0 5px 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }

  /* Styles for the remove button inside the keyword box */
  .keyword-box .remove-btn {
    cursor: pointer;
    margin-left: 5px;
    background-color: #444;
    background-color: none;
    color: white;
  }

  /* Optional: Styles for a submit button */
  .submit-button {
    margin-top: 10px; /* Space between input and button */
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif;
    background-color: #007bff; /* Blue color for button */
    color: #fff; /* Text color */
  }

  .submit-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  /* Result Type Select Tabbar */
  .result-type-select {
    width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }
  
  .result-type-button:hover {
      transform: scale(1.10);
  }

/* Data table page arrows */
.table-footer {
    width: min(calc(100% - 40px), 1400px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}

/* Container for page arrows */
.page-arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
}

/* Styling for the arrow buttons */
.page-arrows button {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 15px;
  margin: 0 5px;
  transition: background-color 0.3s, color 0.3s;
}

/* Styling for arrow buttons on hover */
.page-arrows button:hover {
  background-color: #70a4f8;
  transform: scale(1.05);
}

.page-arrows button:disabled {
  cursor: not-allowed;
}
