.pricing-card {
    width: 600px;
    padding: 20px;
    margin: 50px 20px;
    border-radius: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #333;
    color: #fff; /* Text color */
    transition: all 0.3s ease; /* Smooth transition */
}

/* Add hover effect to the card */
.pricing-card:hover {
    transform: translateY(-5px); /* Move the card slightly up on hover */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Enhance box shadow */
}

/* Style the pricing page buttons */
.pricing-pg-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

@media (max-width: 600px) {
    .pricing-card {
        width: 90%; /* Adjusted width for smaller screens */
        border: none; /* Remove border */
        border-radius: 0; /* Remove border radius */
    }

    .pricing-pg-buttons {
        display: flex;
    }
}