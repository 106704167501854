  /* CSS Grid for API Data */
  .data-container {
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(5, 1fr); /* Five columns, one for each field */
    justify-items: center;
    align-items: flex-start;
    padding: 20px 20px 0 20px;
    border-radius: 10px;
    width: min(calc(100% - 40px), 1400px);
    min-width: 900px;
    background-color: #3d3d3d;
    border-style: solid;
    border-width: 3px;
}

.data-field {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 5px;
    width: 100%;
}

.data-field-title {
    width: 100%;
    border-bottom: 1px solid #ccc;
}

.data-label {
    font-weight: bold;
    text-align: center;
}

.data-cell {
    display: flex;
    width: 100%;
    padding: 15px 0;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #ccc;
    height: 30px;
    max-height: 30px;
}

.data-cell:nth-child(odd) {
    background-color: #555; /* Light gray background color */
}