.keyword-scrape-action-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    width: min(calc(100% - 40px), 1360px);
    min-width: 900px;
    padding: 0 30px;
}

.optimized-text-headers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: min(calc(100% - 80px), 1360px);
    min-width: 900px;
    padding: 0 20px;
}

.keyword-scrape-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    border-radius: 10px;
    border: 3px solid #ad9f2f;
    width: min(calc(100% - 80px), 1360px);
    min-width: 900px;
    padding: 0 20px;
    background-color: #3d3d3d;
}

.keyword-display {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.keyword-display-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #ccc;
    height: 60px;
}

.keyword-display-group:last-child {
    border-bottom: none;
}

.keyword-display-group:nth-child(even) {
    background-color: #555; /* Light gray background color */
}

.keyword-display-cell {
    width: 20%;
    padding: 1rem;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
}

.optimized-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 20px 0;
    border-radius: 10px;
    border: 3px solid #ad9f2f;
    width: min(calc(100% - 80px), 1360px);
    min-width: 900px;
}

.optimized-text-section {
    width: 100%;
    border-bottom: 1px dotted #ccc;
    padding: 20px 0;
}

.optimized-text-section:last-child {
    border-bottom: none;
}