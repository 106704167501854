.error-container {
  background-color: #2b2b2b;
  padding: 25px;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  min-width: 700px;
}

.error-title {
    text-align: center;
    margin: 0 0 20px 0;
}

.error-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.error-msg-item {
    margin: 5px 0;
}