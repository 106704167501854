.account-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 800px;
    padding: 20px;
    margin: 30px 0;
    border: 1px solid #e0e0e0;
    background-color: #333;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.account-info-fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin: 20px 0;
}

.account-info-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0;
    border-bottom: 1px dotted #e0e0e0;
}

.account-field-label {
    font-size: 1.2em;
    flex: 1;
}

.account-field-value {
    flex: 1;
    text-align: right;
}

.cancel-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0;
}

.cancel-btn {
    background-color: #333;
    color: red;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-btn:hover {
    text-decoration: underline;
}