
.spinner-container {
    text-align: center;
}
  
.spinner {
    width: 60px;
    height: 60x;
    position: relative;
    margin: 20px auto;
    cursor: not-allowed;
}
  
.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    margin-left: -25px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
    cursor: not-allowed;
}
  
.loading-icon {
    margin-top: 175px;
    animation: pulse 1s ease-in-out infinite alternate;
}

.loading-msg {
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
    margin-top: 20px;
    cursor: not-allowed;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  
@keyframes pulse {
    0% { transform: scale(1); }
    100% { transform: scale(1.3); }
}
  