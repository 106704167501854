.App {
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #444;
    color: white;
    font-family: 'Ubuntu', sans-serif;
}

/* Adjust scrollbar styling */
/* For Webkit-based browsers */
::-webkit-scrollbar {
  width: 10px;
  padding: 5px;
}

::-webkit-scrollbar-track {
  background: #444; /* Dark background color */
}

::-webkit-scrollbar-thumb {
  background: #666; /* Darker color for the scrollbar thumb */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #888; /* Lighter color on hover */
}

.app-button {
  display: inline-block;
  text-wrap: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s, border-color 0.3s, transform 0.3s;
  font-family: 'Ubuntu', sans-serif;
  background-color: #444;
}

.app-button:hover {
  transform: scale(1.10);
}

.app-button:disabled {
  cursor: not-allowed;
  background-color: #666;
  border-color: #666;
  color: #999;
}

.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(100vh - 275px);
}

.hidden-button {
  display: none;
}

/* Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: max-content;
  max-width: 300px;
  background-color: #555;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  font-size: 15px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -300px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: 100px;
  border-width: 7px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
